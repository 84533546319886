import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  CircularProgress,
  Flex,
  IconButton,
  Progress,
  Text,
} from '@chakra-ui/react';
import { IconChevronLeft } from '@utils/icons';
import { useNavigate, useParams } from 'react-router-dom';
import API from '@utils/api';
import { isEmpty } from 'lodash-es';
import { toast } from 'react-toastify';

function VoteResult() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [vote, setVote] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchVote = () => {
      setIsLoading(true);
      API.user
        .voteResult(id)
        .then((response) => {
          if (response.data === 'not voted') {
            toast.warning('Вы не можете видеть результат голосования');
            navigate('/');
          }
          setVote(response.data);
        })
        .catch(() => {
          toast.error('Не удалось загрузить голосование');
        })
        .finally(() => setIsLoading(false));
    };
    fetchVote();
  }, [id, navigate]);

  return (
    <Box p={3} minH="100vh" maxW="1024px" m="auto">
      <Flex
        alignItems={'center'}
        mb={5}
        onClick={() => navigate('/')}
        cursor={'pointer'}
      >
        <IconButton
          aria-label={'back'}
          icon={<IconChevronLeft />}
          mr={3}
          size={'sm'}
        />
        <Text fontWeight={'semibold'}>Назад</Text>
      </Flex>

      {isLoading && (
        <Flex justifyContent={'center'}>
          <CircularProgress isIndeterminate />
        </Flex>
      )}

      {!isLoading && (
        <>
          <Text fontWeight={'semibold'} fontSize={18}>
            {vote.name}
          </Text>
          {vote.description && <Text fontSize={14}>{vote.description}</Text>}

          <Badge mt={5}>Всего проголосовало: {vote.all_votes}</Badge>

          <Box mt={5}>
            {!isEmpty(vote) &&
              vote.data.map((q) => (
                <Box key={q.id} mb={5} bgColor={'white'} p={2} px={4}>
                  <Text fontWeight={'semibold'} mb={5}>
                    {q.name}
                  </Text>
                  {q.answers.map((a) => (
                    <Box mb={4} key={a.id}>
                      <Text fontSize={15}>{a.text}</Text>
                      <Text fontSize={13} fontWeight={'semibold'}>
                        (голосов: {a.votes})
                      </Text>
                      <Progress value={a.percent} mt={1} />
                    </Box>
                  ))}
                </Box>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
}

export default VoteResult;