import { Navigate } from 'react-router-dom';
import UserMain from '@pages/User/Main';
import AdminMain from '@pages/Admin/Main';
import AdminUsers from '@pages/Admin/Users';
import AdminLogin from '@pages/Admin/Login';
import AdminVoteResult from "@pages/Admin/VoteResult";
import UserLogin from '@pages/User/Login';
import UserRegistration from '@pages/User/Registration';
import UserPasswordRemindRequest from '@pages/User/PasswordRemindRequest';
import UserPasswordReset from '@pages/User/PasswordReset';
import UserVote from "@pages/User/Vote";
import UserVoteResult from "@pages/User/VoteResult";
import UserLayout from "@components/Layout";
import AdminLayout from "@components/Layout/Admin";
import NotFound from "@pages/NotFound";

const routes = (isAdminLoggedIn, isUserLoggedIn) => [
  {
    path: '/admin',
    element: isAdminLoggedIn ? <AdminLayout><AdminMain /></AdminLayout> : <Navigate to="/admin/login" />,
  },
  {
    path: '/admin/users',
    element: isAdminLoggedIn ? <AdminLayout><AdminUsers /></AdminLayout> : <Navigate to="/admin/login" />,
  },
  {
    path: '/admin/login',
    element: isAdminLoggedIn ? <Navigate to="/admin" /> : <AdminLogin/>,
  },
  {
    path: '/admin/vote-result/:id',
    element: isAdminLoggedIn ? <AdminLayout><AdminVoteResult /></AdminLayout> : <Navigate to="/admin/login" />,
  },

  {
    path: '/',
    element: isUserLoggedIn ? <UserLayout><UserMain /></UserLayout> : <Navigate to="/login" />,
  },
  {
    path: '/login',
    element: <UserLogin />,
  },
  {
    path: '/reg',
    element: <UserRegistration />
  },
  {
    path: '/remind-password-request',
    element: <UserPasswordRemindRequest />
  },
  {
    path: '/password-reset',
    element: <UserPasswordReset />
  },
  {
    path: '/vote/:id',
    element: isUserLoggedIn ? <UserLayout><UserVote /></UserLayout> : <Navigate to="/login" />,
  },
  {
    path: '/vote-result/:id',
    element: isUserLoggedIn ? <UserLayout><UserVoteResult /></UserLayout> : <Navigate to="/login" />,
  },
  {
    path: '*',
    element: <NotFound/>
  }
];

export default routes;