import React, {Suspense, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import API from '@utils/api';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Loader from '@components/Loader';
import {IconEyeClose, IconEyeOpen, IconLock} from '@utils/icons';
import {toast} from 'react-toastify';

function PasswordReset() {
  const token = new URLSearchParams(window.location.search).get('token');

  const [isSend, setIsSend] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (token === undefined) {
      window.location.href = '/login';
    }
  }, [token]);

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'Минимальная длина 6 символов')
      .required('Обязательное поле'),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordRepeat: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const data = new FormData();

      data.append('password', values.password);
      data.append('token', token);

      await API.user
        .passwordReset(data)
        .then((response) => {
          if (response.status === 200 && response.data === 'OK') {
            setIsSend(true);
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              if (message?.token) {
                return toast.error('Ошибка! Неверный токен');
              }
            }
          }

          toast.error('Ошибка сервера. Повторите позже');
        });
    },
  });

  return (
    <Suspense fallback={<Loader/>}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        bg={useColorModeValue('gray.200', 'gray.600')}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color={useColorModeValue('brand.blueBlack', 'gray.400')}>
            Введите новый пароль
          </Heading>
          <Box minW={{base: '90%', md: '520px'}} maxW={520}>
            {isSend && (
              <Box
                maxW="600px"
                bg={'whiteAlpha.900'}
                boxShadow="md"
                mt={3}
                p={5}
                px={10}
                textAlign="center"
              >
                <Text>Пароль успешно сменен</Text>

                <Box mt={5}>
                  <Link
                    as={RouterLink}
                    to="/login"
                    color="brand.blueDark"
                    fontWeight={600}
                  >
                    Войти в кабинет
                  </Link>
                </Box>
              </Box>
            )}

            {!isSend && (
              <form onSubmit={formik.handleSubmit}>
                <Stack
                  spacing={4}
                  p="2rem"
                  backgroundColor="whiteAlpha.900"
                  boxShadow="md"
                >
                  <FormControl isInvalid={formik.errors.password}>
                    <FormLabel>Новый пароль</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        children={<IconLock color="gray.300"/>}
                      />
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        {...formik.getFieldProps('password')}
                      />
                      <InputRightElement>
                        <IconButton
                          size="sm"
                          onClick={handleShowClick}
                          icon={
                            showPassword ? <IconEyeClose/> : <IconEyeOpen/>
                          }
                          aria-label="Показать пароль"
                          tabIndex={-1}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {formik.errors.password}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={formik.errors.passwordRepeat}>
                    <FormLabel>Повторить пароль</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        children={<IconLock color="gray.300"/>}
                      />
                      <Input
                        type={showPassword ? 'text' : 'password'}
                        {...formik.getFieldProps('passwordRepeat')}
                      />
                      <InputRightElement>
                        <IconButton
                          size="sm"
                          onClick={handleShowClick}
                          icon={
                            showPassword ? <IconEyeClose/> : <IconEyeOpen/>
                          }
                          aria-label="Показать пароль"
                          tabIndex={-1}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {formik.errors.passwordRepeat}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    variant="solid"
                    width="full"
                    isLoading={formik.isSubmitting}
                  >
                    Сменить
                  </Button>
                </Stack>
              </form>
            )}
          </Box>
        </Stack>
      </Flex>
    </Suspense>
  );
}

export default PasswordReset;
