import React from 'react';
import { Icon } from '@chakra-ui/react';

import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineLock,
  AiOutlineUser,
  AiOutlineLogout,
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
} from 'react-icons/ai';

import {
  HiOutlineChevronLeft,
  HiOutlineChevronDown,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronRight,
  HiChevronLeft,
} from 'react-icons/hi';
import { RiMapPin2Line } from 'react-icons/ri';
import { BsCheckLg } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { BiPencil } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';

export const IconUser = (props) => <Icon as={AiOutlineUser} {...props} />;
export const IconLock = (props) => <Icon as={AiOutlineLock} {...props} />;
export const IconEyeOpen = (props) => <Icon as={AiFillEye} {...props} />;
export const IconEyeClose = (props) => <Icon as={AiFillEyeInvisible} {...props} />;
export const IconLogOut = (props) => <Icon as={AiOutlineLogout} {...props} />;
export const IconPhone = (props) => <Icon as={AiOutlinePhone} {...props} />;
export const IconChevronLeft = (props) => <Icon as={HiOutlineChevronLeft} {...props} />;
export const IconChevronDown = (props) => <Icon as={HiOutlineChevronDown} {...props} />;
export const IconMap = (props) => <Icon as={RiMapPin2Line} {...props} />;
export const IconEmail = (props) => <Icon as={AiOutlineMail} {...props} />;
export const IconCheck = (props) => <Icon as={BsCheckLg} {...props} />;
export const IconRemove = (props) => <Icon as={FaRegTrashAlt} {...props} />;
export const IconEdit = (props) => <Icon as={BiPencil} {...props} />;
export const IconArrowUp = (props) => <Icon as={AiOutlineArrowUp} {...props} />;
export const IconArrowDown = (props) => <Icon as={AiOutlineArrowDown} {...props} />;
export const IconArrowLeftDouble = (props) => <Icon as={HiChevronDoubleLeft} {...props} />;
export const IconArrowRightDouble = (props) => <Icon as={HiChevronDoubleRight} {...props} />;
export const IconArrowRight = (props) => <Icon as={HiChevronRight} {...props} />;
export const IconArrowLeft = (props) => <Icon as={HiChevronLeft} {...props} />;
export const IconExternal = (props) => <Icon as={FiExternalLink} {...props} />;
