import React, { useMemo, useState } from 'react';
import DataTable from '@components/DataTable/Table';
import API from '@utils/api';
import { toast } from 'react-toastify';
import adminStore from '@store/admin';
import { isEmpty } from 'lodash-es';
import { Box, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { IconEdit, IconRemove } from '@utils/icons';
import UserEditModal from '@pages/Admin/UserEditModal';

function prepareParams(data) {
  const params = {};

  if (data.pageIndex) {
    params.page = data.pageIndex;
  }

  if (data.pageSize) {
    params['per-page'] = data.pageSize;
  }

  if (!isEmpty(data.sortBy)) {
    let field = data.sortBy[0].id;
    if (data.sortBy[0].desc === true) {
      field = `-${field}`;
    }
    params.sort = field;
  }

  return params;
}

function Users() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [userId, setUserId] = useState(null);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const fetchData = (pageSize, pageIndex, sortBy) => {
    setIsLoading(true);

    adminStore.setPageIndex(pageIndex);
    adminStore.setPageSize(pageSize);
    adminStore.setSortBy(sortBy);

    const params = prepareParams({ pageSize, pageIndex, sortBy });

    API.admin
      .users(params)
      .then((response) => {
        setTotal(response.headers['x-pagination-total-count']);
        adminStore.setPageCount(
          parseFloat(response.headers['x-pagination-page-count']),
        );
        setData(response.data);
      })
      .catch(() => toast.error('Не удалось получить список пользователей'))
      .finally(() => setIsLoading(false));
  };

  function editUser(id) {
    setUserId(id);
    onOpen();
  }

  function removeUser(id) {
    // eslint-disable-next-line no-restricted-globals
    let result = confirm('Удалить пользователя?');
    if (result) {
      API.user
        .remove(id)
        .then((response) => {
          if (response.status === 204) {
            toast.success('Пользователь удален');
            fetchData(
              adminStore.pageSize,
              adminStore.pageIndex,
              adminStore.sortBy,
            );
          }
        })
        .catch(() => toast.error('Не удалось удалить пользователя'));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Участок',
        accessor: 'area_number',
      },
      {
        Header: 'Фамилия',
        accessor: 'last_name',
      },
      {
        Header: 'Имя',
        accessor: 'name',
      },
      {
        Header: 'Отчество',
        accessor: 'second_name',
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Навигация',
        Cell: ({ row }) => (
          <Flex>
            <IconButton
              aria-label={'edit'}
              icon={<IconEdit />}
              size={'sm'}
              mr={1}
              colorScheme={'blue'}
              onClick={() => editUser(row.original.id)}
            />
            <IconButton
              aria-label={'remove'}
              icon={<IconRemove />}
              size={'sm'}
              colorScheme={'red'}
              onClick={() => removeUser(row.original.id)}
            />
          </Flex>
        ),
      },
    ],
    [],
  );

  return (
    <Box m={5} minH="100vh">
      <Text fontWeight={'semibold'} fontSize={20} mb={5}>
        Список пользователей ({total})
      </Text>

      <UserEditModal
        isOpen={isOpen}
        onClose={onClose}
        userId={userId}
        setUserId={setUserId}
        fetchData={() =>
          fetchData(
            adminStore.pageSize,
            adminStore.pageIndex,
            adminStore.sortBy,
          )
        }
      />

      <Box bgColor={'white'} overflowX={'auto'}>
        <DataTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={adminStore.pageCount}
          pageIndex={adminStore.pageIndex}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}

export default Users;