import React from 'react';
import { Box, CircularProgress, Portal, Text } from '@chakra-ui/react';

const styles = {
  backdrop: {
    backgroundColor: 'rgba(4, 21, 33, 0.7)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 6,
  },
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 6,
    textAlign: 'center',
  },
  loader: {
    color: 'brand.red',
    paddingBottom: '20px',
  },
};

function Loader() {
  return (
    <Portal>
      <div style={styles.backdrop} />
      <Box sx={styles.container}>
        <CircularProgress isIndeterminate sx={styles.loader} />
        <Text color="#fff">Загрузка</Text>
      </Box>
    </Portal>
  );
}

export default Loader;
