import { makeAutoObservable, reaction } from 'mobx';
import { isEmpty, isObject } from 'lodash-es';
import { getFromLocalStorage } from '@utils/common';
import API from '@utils/api';
import { USERNAME, TOKEN } from '@utils/common';

class UserStore {
  isLoading = false;

  user = {};

  username = getFromLocalStorage(USERNAME);

  token = getFromLocalStorage(TOKEN);

  reactionSetLocalstorage = (variable, key) => (
    variable ? localStorage.setItem(key, JSON.stringify(variable)) : localStorage.removeItem(key)
  );

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        this.reactionSetLocalstorage(token, TOKEN);

        if (isEmpty(this.user)) {
          this.getUser();
        }
      },
    );
    reaction(
      () => this.username,
      (username) => {
        this.reactionSetLocalstorage(username, USERNAME);
      },
    );
  }

  getUser() {
    this.setIsLoading(true);

    API.user.me().then((response) => {
      if (response.status === 200 && isObject(response.data)) {
        this.setUser(response.data);
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        this.logout();
      }
    }).finally(() => this.setIsLoading(false));
  }

  setUser(user) {
    this.user = user;
  }

  setToken(token) {
    this.token = token;
  }

  setUsername(username) {
    this.username = username;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  logout() {
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(TOKEN);
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }
}

export default new UserStore();
