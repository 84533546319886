import React from 'react';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IconExternal, IconLogOut } from '@app/utils/icons';
import adminStore from '@store/admin';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box>
      <Flex bgColor={'gray.100'} py={3} px={5}>
        <Flex alignItems={'center'}>
          <Tooltip label="На сайт">
            <IconButton
              aria-label={'goto'}
              icon={<IconExternal />}
              onClick={() => navigate('/')}
            />
          </Tooltip>
          <Badge colorScheme={'blue'}>Администратор</Badge>
        </Flex>

        <Flex ml="auto" alignItems="center">
          <Text mr={3} isTruncated maxW={150}>
            {adminStore.admin.email}
          </Text>
          <IconButton
            icon={<IconLogOut />}
            size="sm"
            variant={'ghost'}
            onClick={adminStore.logout}
            aria-label={'icon'}
          />
        </Flex>
      </Flex>
      <Flex bgColor={'gray.50'} py={2} px={5}>
        <Button
          size={'sm'}
          mr={1}
          colorScheme={location.pathname === '/admin' ? 'blackAlpha' : 'gray'}
          onClick={() => navigate('/admin')}
        >
          Голосования
        </Button>
        <Button
          size={'sm'}
          colorScheme={
            location.pathname === '/admin/users' ? 'blackAlpha' : 'gray'
          }
          onClick={() => navigate('/admin/users')}
        >
          Пользователи
        </Button>
      </Flex>
    </Box>
  );
}

export default observer(Header);
