import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
} from '@chakra-ui/react';
import {IconRemove} from '@utils/icons';
import API from '@utils/api';
import {v4 as uuidv4} from 'uuid';
import {toast} from 'react-toastify';

function VoteModal({
                     isOpen,
                     onClose,
                     fetchVotes = null,
                     voteData = null,
                     setVoteData = null,
                   }) {
  const answer = {
    id: uuidv4(),
    text: '',
  };

  const question = {
    id: uuidv4(),
    name: '',
    answers: [],
  };

  const [voteName, setVoteName] = useState('');
  const [voteDescription, setVoteDescription] = useState('');
  const [questions, setQuestions] = useState([]);
  const [status, setStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const addQuestion = () => {
    const q = [...questions];
    q.push(question);
    setQuestions(q);
  };

  const removeQuestion = (i) => {
    let newFormValues = [...questions];
    newFormValues.splice(i, 1);
    setQuestions(newFormValues);
  };

  const addAnswer = (i) => {
    const q = [...questions];
    q[i].answers.push(answer);
    setQuestions(q);
  };

  const removeAnswer = (questionIndex, answerIndex) => {
    const q = [...questions];
    q[questionIndex].answers.splice(answerIndex, 1);
    setQuestions(q);
  };

  const handleChangeQuestion = (i, e) => {
    let newValues = [...questions];
    newValues[i].name = e.target.value;
    setQuestions(newValues);
  };

  const handleChangeAnswer = (questionIndex, answerIndex, e) => {
    let newValues = [...questions];
    newValues[questionIndex].answers[answerIndex].text = e.target.value;
    setQuestions(newValues);
  };

  const clearAndClose = () => {
    if (typeof setVoteData === 'function') {
      setVoteData(null);
    }

    setVoteDescription('');
    setVoteName('');
    setQuestions([]);
    setStatus(1);
    onClose();
  };

  const saveHandle = () => {
    const data = {
      id: voteData?.id || null,
      name: voteName,
      description: voteDescription,
      questions,
      status,
    };

    setIsLoading(true);

    const rest =
      voteData !== null
        ? API.vote.update(voteData.id, data)
        : API.vote.create(data);

    rest
      .then(() => {
        toast.success('Голосование сохранено');
        clearAndClose();
        if (typeof fetchVotes === 'function') {
          fetchVotes();
        }
        if (typeof setVoteData === 'function') {
          setVoteData(null);
        }
      })
      .catch((error) => {
        toast.error('Не удалось сохранить голосование');
        console.log('Save vote error: ', error?.response);
      })
      .finally(() => setIsLoading(false));
  };

  const handleStatusChange = (e) => {
   setStatus(e.target.value);
  }

  useEffect(() => {
    if (voteData !== null) {
      setVoteName(voteData.name);
      setVoteDescription(voteData.description);
      setQuestions(voteData.questions);
      setStatus(voteData.status);
    }
  }, [voteData]);

  return (
    <Modal isOpen={isOpen} onClose={clearAndClose} size="2xl">
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Голосование</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <FormControl isRequired mb={3}>
            <FormLabel>Название</FormLabel>
            <Input
              type="text"
              value={voteName}
              onChange={(e) => setVoteName(e.target.value)}
            />
          </FormControl>

          {voteData !== null && (
            <FormControl mb={3}>
              <FormLabel>Статус</FormLabel>
              <Select onChange={handleStatusChange} defaultValue={voteData.status}>
                <option value="1">Активно</option>
                <option value="0">Завершено</option>
              </Select>
            </FormControl>
          )}

          <FormControl mb={5}>
            <FormLabel>Описание</FormLabel>
            <Textarea
              value={voteDescription}
              onChange={(e) => setVoteDescription(e.target.value)}
            />
          </FormControl>

          <Button colorScheme={'blue'} onClick={addQuestion} mb={3}>
            Добавить вопрос
          </Button>

          {questions.map((q, questionIndex) => (
            <Box
              key={questionIndex}
              borderWidth={1}
              borderColor={'gray.100'}
              p={3}
              mb={3}
            >
              <FormControl isRequired mb={3}>
                <FormLabel>Вопрос {questionIndex + 1}</FormLabel>
                <Flex alignItems={'center'}>
                  <Input
                    type="text"
                    value={q.name || ''}
                    onChange={(e) => handleChangeQuestion(questionIndex, e)}
                  />
                  <IconButton
                    aria-label={'remove'}
                    icon={<IconRemove/>}
                    ml={2}
                    colorScheme={'red'}
                    onClick={() => removeQuestion(questionIndex)}
                  />
                </Flex>
              </FormControl>

              <Button
                size={'sm'}
                onClick={() => addAnswer(questionIndex)}
                mb={3}
              >
                Добавить ответ
              </Button>

              {q.answers.map((a, answerIndex) => (
                <FormControl isRequired mb={3} key={answerIndex}>
                  <FormLabel ml={3}>Ответ {answerIndex + 1}</FormLabel>
                  <Flex alignItems={'center'}>
                    <Input
                      type="text"
                      ml={3}
                      size={'sm'}
                      value={a.text || ''}
                      onChange={(e) =>
                        handleChangeAnswer(questionIndex, answerIndex, e)
                      }
                    />
                    <IconButton
                      size={'sm'}
                      aria-label={'remove'}
                      icon={<IconRemove/>}
                      ml={2}
                      colorScheme={'red'}
                      onClick={() => removeAnswer(questionIndex, answerIndex)}
                    />
                  </Flex>
                </FormControl>
              ))}
            </Box>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            mr={3}
            onClick={saveHandle}
            isLoading={isLoading}
          >
            Сохранить
          </Button>
          <Button onClick={clearAndClose}>Отмена</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default VoteModal;
