import React, {useEffect, useState} from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import API from '@utils/api';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import * as yup from 'yup';

function UserEditModal({isOpen, onClose, userId, setUserId, fetchData}) {
  const [user, setUser] = useState({});

  const onCloseHandle = () => {
    setUser({});
    formik.resetForm();
    setUserId(null);
    fetchData();
    onClose();
  }

  const fetchUserData = () => {
    API.user
      .get(userId)
      .then((response) => {
        setUser(response.data);
      })
      .catch(() => toast.error('Не удалось получить данные пользователя'));
  };

  const validationSchema = yup.object().shape({
    email: yup.string().max(128).email().required('Введите email'),
    area_number: yup.string().required('Введите номер участка'),
    phone: yup.string().required('Введите телефон'),
    name: yup.string().required('Введите имя'),
    last_name: yup.string().required('Введите фамилию'),
  });

  const formik = useFormik({
    initialValues: {
      name: user.name || '',
      email: user.email || '',
      last_name: user.last_name || '',
      second_name: user.second_name || '',
      phone: user.phone || '',
      area_number: user.area_number || '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = new FormData();

      data.append('name', values.name);
      data.append('email', values.email);
      data.append('last_name', values.last_name);
      data.append('second_name', values.second_name);
      data.append('phone', values.phone);
      data.append('area_number', values.area_number);

      await API.user.update(userId, data).then((response) => {
        if (response.status === 200) {
          toast.success('Сохранено');
          onCloseHandle()
        }
      }).catch(() => toast.error('Не удалось сохранить'));
    },
  });

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Редактировать</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={formik.errors.area_number}>
                <FormLabel>Участок</FormLabel>
                <Input type="text" {...formik.getFieldProps('area_number')} />
                <FormErrorMessage>{formik.errors.area_number}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={formik.errors.phone}>
                <FormLabel>Телефон</FormLabel>
                <Input type="text" {...formik.getFieldProps('phone')} />
                <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={formik.errors.email}>
                <FormLabel>Email</FormLabel>
                <Input type="text" {...formik.getFieldProps('email')} />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={formik.errors.last_name}>
                <FormLabel>Фамилия</FormLabel>
                <Input type="text" {...formik.getFieldProps('last_name')} />
                <FormErrorMessage>{formik.errors.last_name}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={formik.errors.name}>
                <FormLabel>Имя</FormLabel>
                <Input type="text" {...formik.getFieldProps('name')} />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.errors.second_name}>
                <FormLabel>Отчество</FormLabel>
                <Input type="text" {...formik.getFieldProps('second_name')} />
                <FormErrorMessage>{formik.errors.second_name}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              variant="solid"
              mr={3}
              isLoading={formik.isSubmitting}
            >
              Сохранить
            </Button>
            <Button colorScheme="blue" onClick={onClose}>
              Отмена
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default UserEditModal;