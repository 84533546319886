export const USERNAME = 'VOTE_USERNAME';
export const TOKEN = 'VOTE_TOKEN';
export const ADMIN_TOKEN = 'ADMIN_VOTE_TOKEN';
export const ADMIN_USERNAME = 'ADMIN_VOTE_USERNAME';

const getFromLocalStorage = (name) => {
  const storageString = localStorage.getItem(name);
  return JSON.parse(storageString);
}

export { getFromLocalStorage }
