import React from 'react';
import {
  Flex,
  HStack,
  IconButton,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import {
  IconArrowLeft,
  IconArrowLeftDouble,
  IconArrowRight,
  IconArrowRightDouble,
} from '@utils/icons';

function Pagination({
                      gotoPage,
                      previousPage,
                      nextPage,
                      canNextPage,
                      canPreviousPage,
                      pageCount,
                      pageIndex,
                      pageOptions,
                      pageSize,
                      setPageSize,
                    }) {
  if (pageOptions.length > 1) {
    return (
      <Flex _disabled>
        <HStack>
          <IconButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            icon={<IconArrowLeftDouble />}
            aria-label="first"
            size="sm"
          />
          <IconButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            icon={<IconArrowLeft />}
            aria-label="prev"
            size="sm"
          />
          <IconButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
            icon={<IconArrowRight />}
            aria-label="next"
            size="sm"
          />
          <IconButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            icon={<IconArrowRightDouble />}
            aria-label="last"
            size="sm"
          />
        </HStack>

        <Flex alignItems="center" ml={5}>
          <Text fontSize={14}>Страница</Text>
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            key={pageIndex}
            w={10}
            mx={1}
            px={1}
            fontSize={14}
            textAlign="center"
          />
          <Text mx={1} fontSize={14}>
            из
          </Text>
          <Text fontWeight="semibold" fontSize={14} ml={1}>
            {pageOptions.length}
          </Text>
        </Flex>

        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            localStorage.setItem('_votes_page_size', e.target.value);
          }}
          w="80px"
          ml={5}
        >
          {[10, 20, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </Flex>
    );
  }
  return <div />;
}

export default Pagination;
