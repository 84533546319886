import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import VoteModal from '@pages/Admin/VoteModal';
import API from '@utils/api';
import { IconEdit, IconRemove } from '@utils/icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DataTable from '@components/DataTable/Table';
import adminStore from '@store/admin';
import StatusLabel from "@components/StatusLabel";

function Main() {
  const navigate = useNavigate();
  const [votes, setVotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [voteData, setVoteData] = useState(null);

  const fetchVotes = () => {
    setIsLoading(true);
    API.vote
      .list()
      .then((response) => {
        setVotes(response.data);
      })
      .finally(() => setIsLoading(false));
  };

  const removeVote = (id) => {
    API.vote
      .remove(id)
      .then((response) => {
        if (response.data === 'OK') {
          toast.success('Удалено');
          fetchVotes();
        } else {
          toast.error('Не удалось удалить');
        }
      })
      .catch(() => toast.error('Не удалось удалить'));
  };

  const editVote = (id) => {
    API.vote.get(id).then((response) => {
      setVoteData(response.data);
      onOpen();
    });
  };

  useEffect(() => {
    fetchVotes();
  }, []);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Название',
        accessor: 'name',
        Cell: ({ row }) => (
          <Text onClick={() => navigate(`/admin/vote-result/${row.original.id}`)} cursor={'pointer'}>{row.original.name}</Text>
        )
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: ({ value }) => <StatusLabel value={value} />,
      },
      {
        Header: 'Навигация',
        Cell: ({ row }) => (
          <Flex>
            <Button
              onClick={() => navigate(`/admin/vote-result/${row.original.id}`)}
              size="sm"
              variant="outline"
              colorScheme="gray"
              mr={5}
            >
              Результат
            </Button>
            <IconButton
              aria-label={'edit'}
              icon={<IconEdit />}
              size={'sm'}
              mr={1}
              colorScheme={'blue'}
              onClick={() => editVote(row.original.id)}
            />
            <IconButton
              aria-label={'remove'}
              icon={<IconRemove />}
              size={'sm'}
              colorScheme={'red'}
              onClick={() => removeVote(row.original.id)}
            />
          </Flex>
        ),
      },
    ],
    [],
  );

  return (
    <Box m={5} minH="100vh">
      <Text fontWeight={'semibold'} fontSize={20} mb={5}>
        Список голосований
      </Text>

      <Button colorScheme={'green'} onClick={onOpen}>
        Создать
      </Button>

      <VoteModal
        isOpen={isOpen}
        onClose={onClose}
        voteData={voteData}
        fetchVotes={fetchVotes}
        setVoteData={setVoteData}
      />

      <Box bgColor={'white'} overflowX={'auto'} mt={5}>
        <DataTable
          columns={columns}
          data={votes}
          fetchData={fetchVotes}
          pageCount={adminStore.pageCount}
          pageIndex={adminStore.pageIndex}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}

export default Main;