import axios from 'axios';
import { isString } from 'lodash-es';
import { ADMIN_TOKEN, getFromLocalStorage, TOKEN } from '@utils/common';
import userStore from '@store/user';
import adminStore from '@store/admin';

const ApiClient = (token) => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {},
    paramsSerializer: (params) => new URLSearchParams(params),
  };

  if (isString(token) && token !== '') {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  axios.defaults.headers.post.headers = {
    'Content-Type': 'multipart/form-data',
  };

  // debug
  // axios.defaults.params = {};
  // axios.defaults.params.XDEBUG_SESSION_START = 13851;

  return axios.create(config);
};

const AdminApiClient = () => {
  const token = getFromLocalStorage(ADMIN_TOKEN);
  const a = ApiClient(token);

  // middleware for catch 401
  a.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        adminStore.logout();
      }
      return Promise.reject(error);
    },
  );

  return a;
};

const UserApiClient = () => {
  const token = getFromLocalStorage(TOKEN);

  const a = ApiClient(token);

  // middleware for catch 401
  a.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        userStore.logout();
      }
      return Promise.reject(error);
    },
  );

  return a;
};

const API = {
  admin: {
    login: (data) => AdminApiClient().post('admin/login', data),
    me: () => AdminApiClient().get('admin/me'),
    users: (params) => AdminApiClient().get('admin/users', { params }),
    voteResult: (id) => AdminApiClient().get(`admin/vote-result/${id}`),
    vote: (id, params) => AdminApiClient().get(`admin/vote/${id}`, { params }),
    pdf: (id) => AdminApiClient().get(`admin/pdf/${id}`, { responseType: 'blob' }),
  },
  user: {
    login: (data) => UserApiClient().post('user/login', data),
    me: () => UserApiClient().get('user/me'),
    signup: (data) => UserApiClient().post('user/signup', data),
    remind: (data) => UserApiClient().post('user/password-reset-request', data),
    passwordReset: (data) => UserApiClient().post('user/password-reset', data),
    votes: (status) => (status === 'done' || status === 'active') ? UserApiClient().get(`user/votes/${status}`) : UserApiClient().get('user/votes'),
    getVote: (id) => UserApiClient().get(`user/vote/${id}`),
    vote: (id, jsonData) => UserApiClient().post(`user/vote/${id}`, jsonData, { headers: { 'Content-Type': 'application/json' } }),
    voteResult: (id) => UserApiClient().get(`user/vote-result/${id}`),

    remove: (id) => AdminApiClient().delete(`admin/user/${id}`),
    get: (id) => AdminApiClient().get(`admin/user-get/${id}`),
    update: (id, data) => AdminApiClient().post(`admin/user-update/${id}`, data),
  },
  vote: {
    create: (jsonData) => AdminApiClient().post('vote/create', jsonData, { headers: { 'Content-Type': 'application/json' } }),
    update: (id, jsonData) => AdminApiClient().post(`vote/update/${id}`, jsonData, { headers: { 'Content-Type': 'application/json' } }),
    get: (voteId) => AdminApiClient().get(`vote/index/${voteId}`),
    list: () => AdminApiClient().get('vote/list'),
    remove: (id) => AdminApiClient().delete(`vote/index/${id}`),
  },
};

export default API;
