import React, { Suspense, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement, Link,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import API from '@utils/api';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { has } from 'lodash-es';
import Loader from '@components/Loader';
import { IconEyeClose, IconEyeOpen, IconLock, IconPhone } from '@utils/icons';
import { toast } from 'react-toastify';
import userStore from '@store/user';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import InputMask from "react-input-mask";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    phone: yup.string().required('Введите номер телефона'),
    password: yup.string().required('Введите пароль'),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const loginData = new FormData();

      loginData.append('phone', values.phone);
      loginData.append('password', values.password);

      await API.user
        .login(loginData)
        .then((response) => {
          if (response.status !== 200) {
            console.log('error', response);
            return toast.error('Неверный логин или пароль');
          } else if (
            response.status === 200 &&
            has(response.data, 'access_token')
          ) {
            userStore.setToken(response.data.access_token);
            navigate('/');
          } else {
            return toast.error('Не удалось получить токен');
          }
        })
        .catch((error) => {
          toast.error('Server error: ' + error.response.data?.message);
          console.log('error', error.response);
        });
    },
  });

  return (
    <Suspense fallback={<Loader />}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        bg={useColorModeValue('gray.200', 'gray.600')}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Heading>Вход</Heading>
          <Box minW={{ base: '90%', md: '468px' }}>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                spacing={4}
                p="2rem"
                bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
                boxShadow="md"
              >
                <FormControl isInvalid={formik.errors.phone}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconPhone color="gray.300" />}
                    />
                    <Input
                      type="text"
                      as={InputMask}
                      mask="+7(999)999-99-99"
                      placeholder="Телефон"
                      {...formik.getFieldProps('phone')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formik.errors.password}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<IconLock color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Пароль"
                      {...formik.getFieldProps('password')}
                    />
                    <InputRightElement>
                      <IconButton
                        variant="ghost"
                        size="sm"
                        onClick={handleShowClick}
                        icon={showPassword ? <IconEyeClose /> : <IconEyeOpen />}
                        aria-label="Show password"
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  variant="solid"
                  width="full"
                  colorScheme={'blue'}
                  isLoading={formik.isSubmitting}
                >
                  Войти
                </Button>

                <Button onClick={() => navigate('/reg')}>
                  Зарегистрироваться
                </Button>
              </Stack>
            </form>
          </Box>

          <Box pt={5}>
            <Link as={RouterLink} to="/remind-password-request" fontWeight={'semibold'}>Забыли пароль?</Link>
          </Box>
        </Stack>
      </Flex>
    </Suspense>
  );
}

export default Login;
