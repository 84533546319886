import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Header from './header';

function Index({children}) {
  return (
    <Box bg={useColorModeValue('gray.200', 'gray.600')} pb={5} minH="100vh">
      <Header />
      {children}
    </Box>
  );
}

export default Index;
