import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { IconCheck, IconChevronDown } from '@utils/icons';
import { useNavigate } from 'react-router-dom';
import API from '@utils/api';
import { toast } from 'react-toastify';

function Main() {
  const navigate = useNavigate();
  const [votesFilter, setVotesFilter] = useState(null);
  const [votes, setVotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    API.user
      .votes(votesFilter)
      .then((response) => {
        setVotes(response.data);
      })
      .catch(() => toast.error('Не удалось загрузить список голосований'))
      .finally(() => setIsLoading(false));
  }, [votesFilter]);

  return (
    <Box p={3} minH="100vh" maxW="1024px" m="auto">
      <Text fontWeight={'semibold'} fontSize={20} mb={5}>
        Голосования
      </Text>

      <Box mb={5}>
        <Menu>
          <MenuButton as={Button} rightIcon={<IconChevronDown />} w="100%">
            {votesFilter === null
              ? 'Все голосования'
              : votesFilter === 'active'
              ? 'Открытые'
              : 'Завершенные'}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setVotesFilter(null)}>
              Все голосования
            </MenuItem>
            <MenuItem onClick={() => setVotesFilter('active')}>
              Открытые
            </MenuItem>
            <MenuItem onClick={() => setVotesFilter('done')}>
              Завершенные
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      {isLoading && (
        <Flex justifyContent={'center'}>
          <CircularProgress isIndeterminate />
        </Flex>
      )}

      {votes.map((vote) => (
        <Box
          bgColor={'white'}
          borderWidth={1}
          borderColor={'gray.300'}
          mb={5}
          borderRadius={5}
          p={5}
          position={'relative'}
          key={vote.id}
          color={vote.status === '0' ? 'gray.500' : 'black'}
          boxShadow={vote.status === '0' ? 'none' : 'md'}
        >
          {vote.isVoted && (
            <IconCheck
              color={'green.300'}
              position={'absolute'}
              right={4}
              top={6}
              w={5}
              h={5}
            />
          )}

          <Text
            fontWeight={'semibold'}
            mr={5}
            cursor={'pointer'}
            onClick={() => {
              vote.status === '0' || vote.isVoted !== null
                ? navigate(`/vote-result/${vote.id}`)
                : navigate(`/vote/${vote.id}`);
            }}
          >
            {vote.name}
          </Text>
          {vote.description && (
            <Text fontSize={14} mt={2} mr={5}>
              {vote.description}
            </Text>
          )}
          <Flex justifyContent="flex-end">
            <Button
              colorScheme={vote.status === '1' ? 'blue' : 'gray'}
              mt={5}
              onClick={() => {
                vote.status === '0' || vote.isVoted !== null
                  ? navigate(`/vote-result/${vote.id}`)
                  : navigate(`/vote/${vote.id}`);
              }}
              variant={
                vote.status === '0' || vote.isVoted !== null
                  ? 'outline'
                  : 'solid'
              }
            >
              {vote.status === '0' || vote.isVoted !== null
                ? 'Результат'
                : 'Голосовать'}
            </Button>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}

export default Main;
