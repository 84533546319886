import React from 'react';
import { Badge, Flex, IconButton, Text } from '@chakra-ui/react';
import { IconLogOut } from '@app/utils/icons';
import userStore from '@store/user';
import { observer } from 'mobx-react-lite';

function Header() {
  return (
    <Flex bgColor={'gray.100'} py={3} px={3}>
      <Flex alignItems={'center'}>
        <Badge colorScheme={'blue'}>
          Участок: {userStore.user.area_number}
        </Badge>
      </Flex>

      <Flex ml="auto" alignItems="center">
        <Text mr={3} isTruncated maxW={150}>
          {userStore.user.last_name} {userStore.user.name}
        </Text>
        <IconButton
          icon={<IconLogOut />}
          size="sm"
          variant={'ghost'}
          onClick={userStore.logout}
          aria-label={'icon'}
        />
      </Flex>
    </Flex>
  );
}

export default observer(Header);
