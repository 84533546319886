import React from 'react';
import { Badge } from '@chakra-ui/react';

const statusList = ['Завершено', 'Активно'];

function StatusLabel({ value }) {
  return (
    <Badge
      variant="solid"
      colorScheme={
        value === '0'
          ? 'gray'
          : 'green'
      }
    >
      {statusList[value] || value}
    </Badge>
  );
}

export default StatusLabel;
