import React, { Suspense, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement, Link,
  Stack, Text,
  useColorModeValue,
} from '@chakra-ui/react';
import API from '@utils/api';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Loader from '@components/Loader';
import {
  IconChevronLeft,
  IconEmail,
  IconEyeClose,
  IconEyeOpen,
  IconLock,
  IconMap,
  IconPhone,
  IconUser,
} from '@utils/icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import userStore from '@store/user';
import { has } from 'lodash-es';

function Registration() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    area_number: yup.string().required('Имя обязательно'),
    email: yup
      .string()
      .max(128, 'Превышена максимальная длина поля')
      .required('Email обязателен'),
    name: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Имя обязательно'),
    last_name: yup
      .string()
      .max(64, 'Превышена максимальная длина поля')
      .required('Фамилия обязательна'),
    second_name: yup.string().max(64, 'Превышена максимальная длина поля'),
    phone: yup.string().required('Обязательное поле'),
    password: yup
      .string()
      .min(3, 'Минимальная длина 3 символов')
      .required('Обязательное поле'),
    passwordRepeat: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      area_number: '',
      phone: '',
      name: '',
      second_name: '',
      last_name: '',
      password: '',
      passwordRepeat: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setErrors }) => {
      const loginData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        let v = value;
        // Убираем все, кроме цифр
        if (key === 'phone') {
          v = value.replace(/[^\d]/g, '');
        }

        loginData.append(key, v);
      });

      await API.user
        .signup(loginData)
        .then((response) => {
          if (
            (response.status === 200 || response.status === 201) &&
            has(response.data, 'access_token')
          ) {
            toast.success('Успешно!');
            userStore.setToken(response.data.access_token);
            navigate('/');
          } else {
            toast.error('Ошибка!');
            // eslint-disable-next-line no-console
            console.log('Signup error', response);
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            // Ошибка валидации

            const description = [];
            if (error.response?.data?.message) {
              const message = JSON.parse(error.response.data.message);
              setErrors(message);

              // eslint-disable-next-line
              for (const [_, value] of Object.entries(message)) {
                description.push(`- ${value}`);
              }
              const html = `Ошибка!\n${description.join('\n')}`;
              return toast.error(html);
            }

            toast.error('Ошибка!');
          } else if (error.response?.status === 500) {
            // Ошибка сервера

            if (error.response?.data?.message) {
              toast.error(error.response.data.message);
            }
          } else toast.error('Неизвестная ошибка. Повторите позже');
        });
    },
  });

  return (
    <Suspense fallback={<Loader />}>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        bg={useColorModeValue('gray.200', 'gray.600')}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
          maxW={300}
        >
          <Heading>Регистрация</Heading>
          <Box minW={{ base: '90%', md: '468px' }}>
            <form onSubmit={formik.handleSubmit}>
              <Stack
                spacing={4}
                p="2rem"
                bg={useColorModeValue('whiteAlpha.900', 'gray.800')}
                boxShadow="md"
              >
                <FormControl isRequired isInvalid={formik.errors.last_name}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconUser color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="Фамилия"
                      {...formik.getFieldProps('last_name')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.last_name}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={formik.errors.name}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconUser color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="Имя"
                      {...formik.getFieldProps('name')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formik.errors.second_name}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconUser color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="Отчество"
                      {...formik.getFieldProps('second_name')}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.second_name}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={formik.errors.phone} mb={5}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconPhone color="gray.300" />}
                    />
                    <Input
                      type="text"
                      as={InputMask}
                      mask="+7(999)999-99-99"
                      placeholder="Телефон"
                      {...formik.getFieldProps('phone')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formik.errors.email}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconEmail color="gray.300" />}
                    />
                    <Input
                      type="email"
                      placeholder="Email"
                      {...formik.getFieldProps('email')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formik.errors.area_number}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<IconMap color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="Номер участка"
                      {...formik.getFieldProps('area_number')}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.area_number}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={formik.errors.password}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<IconLock color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Пароль"
                      {...formik.getFieldProps('password')}
                    />
                    <InputRightElement>
                      <IconButton
                        variant="ghost"
                        size="sm"
                        onClick={handleShowClick}
                        icon={showPassword ? <IconEyeClose /> : <IconEyeOpen />}
                        aria-label="Show password"
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={formik.errors.passwordRepeat}
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<IconLock color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Повторить пароль"
                      {...formik.getFieldProps('passwordRepeat')}
                    />
                    <InputRightElement>
                      <IconButton
                        variant="ghost"
                        size="sm"
                        onClick={handleShowClick}
                        icon={showPassword ? <IconEyeClose /> : <IconEyeOpen />}
                        aria-label="Показать пароль"
                        tabIndex={-1}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.passwordRepeat}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  variant="solid"
                  width="full"
                  colorScheme={'blue'}
                  isLoading={formik.isSubmitting}
                >
                  Зарегистрироваться
                </Button>

                <Text fontSize={12}>
                  Нажимая кнопку "Зарегистрироваться", Вы даете <Link color='blue.500' href="/soglasie.pdf">согласие на обработку своих персональных данных</Link>.
                </Text>

                <Button
                  onClick={() => navigate('/login')}
                  leftIcon={<IconChevronLeft />}
                >
                  Вход
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </Suspense>
  );
}

export default Registration;
