import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Progress,
  Tab,
  Table,
  TableCaption,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { IconCheck, IconChevronLeft } from '@utils/icons';
import { useNavigate, useParams } from 'react-router-dom';
import API from '@utils/api';
import { isEmpty } from 'lodash-es';
import { toast } from 'react-toastify';

function VoteResult() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [vote, setVote] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [voteInfo, setVoteInfo] = useState([]);

  useEffect(() => {
    const fetchVote = () => {
      setIsLoading(true);
      API.admin
        .voteResult(id)
        .then((response) => {
          setVote(response.data);
        })
        .catch(() => {
          toast.error('Не удалось загрузить голосование');
        })
        .finally(() => setIsLoading(false));
    };
    fetchVote();

    const fetchVoteInfo = () => {
      API.admin
        .vote(id, { expand: 'user' })
        .then((response) => {
          setVoteInfo(response.data);
        })
        .catch(() => {
          toast.error('Не удалось загрузить данные голосования');
        });
    };
    fetchVoteInfo();
  }, [id, navigate]);

  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const pdfHandle = () => {
    setIsPdfLoading(true);
    API.admin
      .pdf(id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${vote.name}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        toast.error('Не удалось сохранить');
      })
      .finally(() => setIsPdfLoading(false));
  };

  return (
    <Box p={3}>
      <Flex
        alignItems={'center'}
        mb={5}
        onClick={() => navigate('/admin')}
        cursor={'pointer'}
      >
        <IconButton
          aria-label={'back'}
          icon={<IconChevronLeft />}
          mr={3}
          size={'sm'}
        />
        <Text fontWeight={'semibold'}>Назад</Text>
      </Flex>

      {isLoading && (
        <Flex justifyContent={'center'}>
          <CircularProgress isIndeterminate />
        </Flex>
      )}

      {!isLoading && (
        <>
          <Text fontWeight={'semibold'} fontSize={18}>
            {vote.name}
          </Text>
          {vote.description && <Text fontSize={14}>{vote.description}</Text>}

          <Badge mt={5}>Всего проголосовало: {vote.all_votes}</Badge>
        </>
      )}

      <Tabs mt={5}>
        <TabList>
          <Tab _selected={{ bg: 'white', boxShadow: 'none' }}>Результат</Tab>
          <Tab _selected={{ bg: 'white', boxShadow: 'none' }}>Протокол</Tab>
        </TabList>

        <TabPanels bgColor={'white'}>
          <TabPanel>
            <Box mt={5}>
              {!isEmpty(vote) &&
                vote.data.map((q) => (
                  <Box key={q.id} mb={5} bgColor={'white'} p={2} px={4}>
                    <Text fontWeight={'semibold'} mb={5}>
                      {q.name}
                    </Text>
                    {q.answers.map((a) => (
                      <Box mb={4} key={a.id}>
                        <Text fontSize={15}>{a.text}</Text>
                        <Text fontSize={13} fontWeight={'semibold'}>
                          (голосов: {a.votes})
                        </Text>
                        <Progress value={a.percent} mt={1} />
                      </Box>
                    ))}
                  </Box>
                ))}
            </Box>
          </TabPanel>

          <TabPanel>
            <Button onClick={pdfHandle} size={'sm'} isLoading={isPdfLoading}>
              Сохранить в PDF
            </Button>

            {vote.data &&
              vote.data.map((q) => (
                <Box overflowX={'auto'} key={q.id}>
                  <Table mb={5} bgColor={'white'} size={'sm'}>
                    <TableCaption
                      placement={'top'}
                      fontSize={15}
                      fontWeight={'semibold'}
                    >
                      {q.name}
                    </TableCaption>
                    <Thead>
                      <Tr>
                        <Td />
                        {q.answers.map((a) => (
                          <Th key={a.id}>{a.text}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {voteInfo.map((item) => {
                        if (item.question_id === q.id) {
                          return (
                            <Tr key={item.id}>
                              <Td>
                                {item.user.last_name} {item.user.name} (уч. {item.user.area_number})
                              </Td>
                              {q.answers.map((a) => (
                                <Td key={a.id}>
                                  {a.id === item.answer_id && <IconCheck />}
                                </Td>
                              ))}
                            </Tr>
                          );
                        }
                      })}
                    </Tbody>
                  </Table>
                </Box>
              ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default VoteResult;