import { makeAutoObservable, reaction } from 'mobx';
import { isEmpty, isObject } from 'lodash-es';
import { getFromLocalStorage } from '@utils/common';
import API from '@utils/api';
import { ADMIN_USERNAME, ADMIN_TOKEN } from '@utils/common';

class AdminStore {
  pageSize = 0;

  pageIndex = 0;

  pageCount = 0;

  sortBy = {};

  isLoading = false;

  admin = {};

  username = getFromLocalStorage(ADMIN_USERNAME);

  token = getFromLocalStorage(ADMIN_TOKEN);

  reactionSetLocalstorage = (variable, key) => (
    variable ? localStorage.setItem(key, JSON.stringify(variable)) : localStorage.removeItem(key)
  );

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        this.reactionSetLocalstorage(token, ADMIN_TOKEN);

        if (isEmpty(this.admin)) {
          this.getUser();
        }
      },
    );
    reaction(
      () => this.username,
      (username) => {
        this.reactionSetLocalstorage(username, ADMIN_USERNAME);
      },
    );
  }

  getUser() {
    this.setIsLoading(true);

    API.admin.me().then((response) => {
      if (response.status === 200 && isObject(response.data)) {
        this.setAdmin(response.data);
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        this.logout();
      }
    }).finally(() => this.setIsLoading(false));
  }

  setAdmin(admin) {
    this.admin = admin;
  }

  setToken(token) {
    this.token = token;
  }

  setUsername(username) {
    this.username = username;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setPageSize(value) {
    this.pageSize = value;
  }

  setPageIndex(value) {
    this.pageIndex = value;
  }

  setPageCount(value) {
    this.pageCount = value;
  }

  setSortBy(value) {
    this.sortBy = value;
  }


  logout() {
    localStorage.removeItem(ADMIN_USERNAME);
    localStorage.removeItem(ADMIN_TOKEN);
    window.location.href = `${process.env.PUBLIC_URL}/admin/login`;
  }
}

export default new AdminStore();
