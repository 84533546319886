import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { IconChevronLeft } from '@utils/icons';
import { useNavigate, useParams } from 'react-router-dom';
import API from '@utils/api';
import { findIndex, isEmpty } from 'lodash-es';
import find from 'lodash-es/find';
import {toast} from "react-toastify";

function Vote() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [vote, setVote] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [answerWithNullId, setAnswerWithNullId] = useState(undefined);
  const [isVoteLoading, setIsVoteLoading] = useState(false);

  const fetchVote = () => {
    setIsLoading(true);
    API.user
      .getVote(id)
      .then((response) => {
        if (response.data?.isVoted !== null) {
          navigate(`/vote-result/${response.data.id}`);
        }
        setVote(response.data);
        const { data } = response.data;
        const a = [];
        data.forEach((question) => {
          a.push({
            questionId: question.id,
            answerId: null,
          });
        });
        setAnswers(a);
      })
      .catch(() => {
        toast.error('Не удалось загрузить голосование');
      })
      .finally(() => setIsLoading(false));
  };

  const radioHandle = (questionId, answerId) => {
    const a = [...answers];
    const index = findIndex(a, (item) => item.questionId === questionId);

    if (index !== -1) {
      a[index].answerId = answerId;
      setAnswers(a);
    }
  };

  const handleVote = () => {
    setIsVoteLoading(true);
    API.user
      .vote(vote.id, answers)
      .then((response) => {
        if (response.data === 'OK') {
          toast.success('Ваш голос учтен');
          navigate('/');
        }
      })
      .catch(() => {
        toast.error('Не удалось проголосовать');
      })
      .finally(() => setIsVoteLoading(false));
  };

  useEffect(() => {
    const answerWithNullId = find(answers, { answerId: null });
    setAnswerWithNullId(answerWithNullId);
  }, [answers]);

  useEffect(() => {
    fetchVote();
  }, [id]);

  return (
    <Box p={3} minH="100vh" maxW="1024px" m="auto">
      <Flex
        alignItems={'center'}
        mb={5}
        onClick={() => navigate('/')}
        cursor={'pointer'}
      >
        <IconButton
          aria-label={'back'}
          icon={<IconChevronLeft />}
          mr={3}
          size={'sm'}
        />
        <Text fontWeight={'semibold'}>Назад</Text>
      </Flex>

      {isLoading && (
        <Flex justifyContent={'center'}>
          <CircularProgress isIndeterminate />
        </Flex>
      )}

      {!isLoading && (
        <>
          <Text fontWeight={'semibold'} fontSize={18}>
            {vote.name}
          </Text>
          {vote.description && <Text fontSize={14}>{vote.description}</Text>}

          <Box mt={5}>
            {!isEmpty(vote) &&
              vote.data.map((q) => (
                <Box key={q.id} mb={5} bgColor={'white'} p={2} px={4}>
                  <Text fontWeight={'semibold'} mb={5}>
                    {q.name}
                  </Text>
                  <RadioGroup onChange={(e) => radioHandle(q.id, e)}>
                    {q.answers.map((a) => (
                      <Box mb={2} key={a.id}>
                        <Radio
                          value={a.id}
                          size={'lg'}
                          borderColor={'gray.400'}
                        >
                          <Text fontSize={15}>{a.text}</Text>
                        </Radio>
                      </Box>
                    ))}
                  </RadioGroup>
                </Box>
              ))}
          </Box>

          <Button
            colorScheme={'green'}
            isDisabled={!!answerWithNullId}
            onClick={handleVote}
            isLoading={isVoteLoading}
          >
            Проголосовать
          </Button>
        </>
      )}
    </Box>
  );
}

export default Vote;