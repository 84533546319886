import { ChakraProvider } from '@chakra-ui/react';
import { useRoutes } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routes from '@app/routes';
import { ADMIN_TOKEN, getFromLocalStorage, TOKEN } from '@utils/common';
import theme from './theme';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import userStore from '@store/user';
import adminStore from '@store/admin';
import { isEmpty } from 'lodash-es';
import { toJS } from 'mobx';

toast.configure({
  autoClose: 3000,
  draggable: false,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnVisibilityChange: true,
  pauseOnHover: true,
  theme: 'colored',
});

function App() {
  const isUserAuthenticated = getFromLocalStorage(TOKEN);
  const isAdminAuthenticated = getFromLocalStorage(ADMIN_TOKEN);

  useEffect(() => {
    if (
      !!isUserAuthenticated && userStore.token !== null && isEmpty(toJS(userStore.user))
    ) {
      userStore.getUser();
    }
    if (
      !!isAdminAuthenticated && adminStore.token !== null && isEmpty(toJS(adminStore.admin))
    ) {
      adminStore.getUser();
    }
  }, []);

  const routing = useRoutes(
    routes(!!isAdminAuthenticated, !!isUserAuthenticated),
  );

  return <ChakraProvider theme={theme}>{routing}</ChakraProvider>;
}

export default observer(App);
